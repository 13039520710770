"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLocalAppCheckToken = getLocalAppCheckToken;
exports.fetchAppCheckToken = fetchAppCheckToken;
exports.setAppCheckToken = setAppCheckToken;
exports.refreshAppCheckToken = refreshAppCheckToken;
exports.getAppCheckToken = getAppCheckToken;
const app_1 = require("firebase/app");
const app_check_1 = require("firebase/app-check");
const firebaseConfig = {
    apiKey: "AIzaSyD9NuEFSRabZseK4AHHvVpZCo4A4zXc7HU",
    authDomain: "makalam.firebaseapp.com",
    projectId: "makalam",
    storageBucket: "makalam.appspot.com",
    messagingSenderId: "730959529455",
    appId: "1:730959529455:web:637da2e826e5df3264a21a",
    measurementId: "G-EX8C2906GQ",
};
var appCheck;
function getLocalAppCheckToken() {
    const appCheckTokenJSON = localStorage.getItem("appCheckToken");
    if (appCheckTokenJSON) {
        const appCheckToken = JSON.parse(appCheckTokenJSON);
        const timeExpiration = appCheckToken.expireTimeMillis;
        const now = new Date();
        if (now.getTime() < timeExpiration) {
            return appCheckToken.token;
        }
    }
    localStorage.removeItem("appCheckToken");
    return null;
}
function fetchAppCheckToken() {
    return __awaiter(this, void 0, void 0, function* () {
        if (!appCheck)
            return "";
        try {
            const response = yield (0, app_check_1.getToken)(appCheck, false);
            const now = new Date();
            // AppCheckToken actually expires after 60 minutes. But to avoid conflicts let's refresh it in 55 minutes.
            const appCheckToken = {
                token: response.token,
                expireTimeMillis: now.getTime() + 55 * 60 * 1000, // 55 minutes.
            };
            localStorage.setItem("appCheckToken", JSON.stringify(appCheckToken));
            return appCheckToken.token;
        }
        catch (e) {
            console.log(e);
        }
        return "";
    });
}
function setAppCheckToken(token) {
    window.$axios.defaults.headers.common["X-Firebase-AppCheck"] = token;
    window.appCheckToken = token;
}
function refreshAppCheckToken() {
    return __awaiter(this, void 0, void 0, function* () {
        const token = yield fetchAppCheckToken();
        setAppCheckToken(token);
        return token;
    });
}
if (GA_ENABLED) {
    const app = (0, app_1.initializeApp)(firebaseConfig);
    appCheck = (0, app_check_1.initializeAppCheck)(app, {
        provider: new app_check_1.ReCaptchaEnterpriseProvider(RECAPTCHA_SITE_KEY),
        isTokenAutoRefreshEnabled: true,
    });
}
function getAppCheckToken() {
    return __awaiter(this, void 0, void 0, function* () {
        if (appCheck) {
            const token = getLocalAppCheckToken();
            if (token) {
                setAppCheckToken(token);
                return token;
            }
            else {
                return yield refreshAppCheckToken();
            }
        }
        return "";
    });
}
window.getAppCheckToken = getAppCheckToken;
