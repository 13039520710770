"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateFeatureRatingTag = generateFeatureRatingTag;
function generateFeatureRatingTag(feature) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(`
        <form id="feature-rating-form"
            class="z-100 flex items-center gap-5 flex-col p-4 mb-4 w-full max-w-xs text-primary-500 bg-white rounded-lg shadow dark:text-primary-400 dark:bg-primary-700"
            role="alert">
            <input name="feature" type="hidden" value="${feature.id}">
            <div class="flex items-start justify-between">
                <p class="font-bold max-w-[250px]">
                    Täze "${feature.name}" aýratynlygyny haladyňyzmy?
                </p>
                <button type="button"
                        id="close-feature-rating"
                        class="ml-3 -mx-1.5 -my-1.5 bg-white text-primary-400 hover:text-primary-900 rounded-lg focus:ring-2 focus:ring-primary-300 p-1.5 hover:bg-primary-100 inline-flex h-8 w-8 dark:text-primary-500 dark:hover:text-white dark:bg-primary-700 dark:hover:bg-primary-700"
                        aria-label="Close">
                    <i class="fa-solid fa-xmark w-5 h-5"></i>
                </button>
            </div>
            <div class="flex justify-between w-full items-center text-3xl">
                <div value="1" class="rating cursor-pointer hover:transition-all hover:-translate-y-2 hover:scale-150">☹️</div>
                <div value="2" class="rating cursor-pointer hover:transition-all hover:-translate-y-2 hover:scale-150">🙁</div>
                <div value="3" class="rating cursor-pointer hover:transition-all hover:-translate-y-2 hover:scale-150">😐</div>
                <div value="4" class="rating cursor-pointer hover:transition-all hover:-translate-y-2 hover:scale-150">🙂</div>
                <div value="5" class="rating cursor-pointer hover:transition-all hover:-translate-y-2 hover:scale-150">😀</div>
            </div>
            <textarea name="feedback" id="feedback" placeholder="Teswir (Hökmany däl)" rows="3" class="block p-2.5 w-full text-sm text-primary-900 bg-primary-50 rounded-lg shadow-sm border border-primary-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-primary-700 dark:border-primary-600 dark:placeholder-primary-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"></textarea>
            <button type="submit" id="submit-button" disabled
                    class="self-end py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                Iberiň
            </button>
        <form/>
        `, "text/html");
    const messagesContainer = document.querySelector(".messages");
    const form = doc.body.children[0];
    setTimeout(() => {
        messagesContainer.appendChild(form);
        addListeners(feature);
    }, 1000);
}
function addListeners(feature) {
    var _a;
    const form = document.querySelector("#feature-rating-form");
    (_a = form
        .querySelector("#close-feature-rating")) === null || _a === void 0 ? void 0 : _a.addEventListener("click", () => {
        form.remove();
        localStorage.setItem(`ignore-feature-${feature.id}`, "true");
    });
    const submitButton = form.querySelector("#submit-button");
    const ratingButtons = form.querySelectorAll(".rating");
    let rating;
    function resetRatingButtons() {
        ratingButtons.forEach((button) => button.classList.remove("-translate-y-2", "scale-150"));
    }
    ratingButtons.forEach((button) => {
        button.addEventListener("click", () => {
            rating = button.getAttribute("value");
            submitButton.disabled = false;
            resetRatingButtons();
            button.classList.add("-translate-y-2", "scale-150");
        });
    });
    form.addEventListener("submit", (e) => __awaiter(this, void 0, void 0, function* () {
        e.preventDefault();
        const data = new FormData(form);
        data.append("rating", rating);
        try {
            const response = yield window.$axios.post("/api/features/rating/", data);
            window.validateResponse(response, true);
            form.remove();
            window.toastSuccess("Baha bereniňiz üçin sagboluň!");
        }
        catch (error) {
            window.toastWarning(error);
        }
    }));
}
